import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import { customStyles } from "../../../helper/customStyles";
import { useFormik } from "formik";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";

const AddChildAttribute = () => {

    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];

    const [data, setdata] = useState({
        p_child_attribute_name: "",
        p_product_attribute_id: null,
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null
    });



    const [initialValues, setInitialValues] = useState({
        p_child_attribute_name: "",
        p_product_attribute_id: null,
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null,
        //childValue: ""
    })
    const [loading, setLoading] = useState(true);
    const { child_id } = useParams();
    const [editChildData, setEditChildData] = useState([]);
    const [parentDataOption, setParentDataOption] = useState([]);
    const [selectParentAttribute, setSelectParentAttribute] = useState({})
    const [selectStatusFlag, setSelectStatusFlag] = useState(null)
    const [inputValue, setInputValue] = useState('');
    const [submitLoader, setSubmitLoader] = useState(false)
    const [items, setItems] = useState([]);
    const navigate = useNavigate()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            const value = inputValue.trim();
            if (!items.includes(value)) {
                setItems([...items, value]);
            }
            setInputValue('');
        }
    };

    const getParentAttributes = async () => {
        try {
            const resp = await MasterServices.getAttributes();
            console.log("getParentAttributes", resp)
            if (resp?.data?.data?.length > 0) {
                console.log("setParentDataOption", resp?.data?.data)
                setLoading(false)
                const filterData = resp?.data?.data
                    .filter(ele => ele?.p_product_attribute_active === "Y")
                    .map(ele => ({
                        label: ele?.p_product_attribute_name,
                        value: ele?.p_product_attribute_id
                    }));


                setParentDataOption(filterData);
                setLoading(false)
            }

        } catch (error) {
            toast.error(error.data.message)
            setParentDataOption([]);
            setLoading(false)

        }
    }

    const getSingleChildAttribute = async (id) => {
        try {
            const response = await MasterServices.getChildAttriValueByid(id);
            console.log("getSingleChildAttribute", response)
            if (response?.data?.data?.length > 0) {
                setEditChildData(response?.data?.data)
            } else {
                setEditChildData([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getParentAttributes()
        }
        fetchData()
    }, [])

    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,

    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {



            if (editChildData?.length > 0) {
                const body = {
                    p_child_attribute_name: values.p_child_attribute_name,
                    p_product_attribute_id: selectParentAttribute?.value ? selectParentAttribute?.value : null,
                    p_child_attribute_id: child_id ? parseInt(child_id) : null,
                    p_child_attribute_active: selectStatusFlag.value ? selectStatusFlag?.value : null
                }
                console.log("body", body)
                editChildAttribute(body)
            } else {
                const body = {
                    p_child_attribute_name: values.p_child_attribute_name,
                    p_product_attribute_id: selectParentAttribute?.value ? selectParentAttribute?.value : null,
                    slug: values.slug,
                    m_meta_title: values.m_meta_title,
                    m_meta_desc: values.m_meta_desc,
                    m_meta_tags: values.m_meta_tags,
                    p_child_attribute_active: selectStatusFlag.value ? selectStatusFlag?.value : null
                }
                console.log("body", body)
                addChildAttribute(body)
            }

        }
    })

    const editChildAttribute = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editAttributeChild(payload);
            console.log("editChildAttribute", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/childAttribute")
            }else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("editChildAttribute", error)
        }
    }

    const addChildAttribute = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addAttributeChild(payload);
            console.log("addChildAttribute", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/childAttribute")
            }else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("addChildAttribute", error)
        }
    }

    useEffect(() => {
        if (editChildData?.length > 0) {
            editChildData.forEach((ele) => {
                if (ele?.p_child_attribute_name) {
                    setInitialValues((prev) => ({
                        ...prev,
                        p_child_attribute_name: ele?.p_child_attribute_name
                    }))
                }


                if (ele?.p_product_attribute_id) {
                    //console.log("parentDataOption",parentDataOption)
                    parentDataOption?.length > 0 && parentDataOption?.forEach((item) => {
                        if (parseInt(ele?.p_product_attribute_id) === parseInt(item?.value)) {
                            setSelectParentAttribute({
                                label: item.label,
                                value: item.value
                            })
                        }
                    })
                }

                if (ele?.p_child_attribute_active) {
                    statusOption.forEach((item) => {
                        if (item?.value === ele?.p_child_attribute_active) {
                            setSelectStatusFlag({
                                label: item.label,
                                value: item.value
                            })
                        }
                    })
                }


            })
        }
    }, [editChildData, parentDataOption])










    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setdata((data) => ({ ...data, [e.target.name]: e.target.value }))
    //     // console.log(e.target.value)
    //     // console.log(e.target.name)
    // }

    const handleDropdown = (val) => {
        console.log(typeof (data.value))
        setdata(data => ({ ...data, p_product_attribute_id: val.value }))

    }


    const submitform = async () => {
        // console.log(data, "this is data")

        let finaldata = data;
        finaldata = { ...finaldata, items }

        let resp = await MasterServices.addAttributeChild(finaldata)
        if (resp.data.success) {
            console.log(resp.data)
            setdata({
                "p_child_attribute_name": "",
                "p_product_attribute_id": null,
                "slug": null,
                "m_meta_title": null,
                "m_meta_desc": null,
                "m_meta_tags": null
            });
            setItems([]);
            setInputValue("")
        }
        else {
            console.log(resp.data)
        }
    }

    const clearForm = () => {
        navigate("/childAttribute")
    }

    useEffect(() => {
        const fetchData = async () => {
            if (child_id) {
                await getSingleChildAttribute(child_id)
            }
        }
        fetchData()
    }, [child_id])


    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{child_id ? "Edit Child Attribute" : "Add Child Attribute"}</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 "
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit()
                }}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="">
                            <input
                                value={values.p_child_attribute_name}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="p_child_attribute_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Parent Attribute
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectParentAttribute}
                                options={parentDataOption}
                                onChange={(selectedOption) => {
                                    setSelectParentAttribute(selectedOption)
                                }}
                                styles={customStyles}
                                name="selectParentAttribute"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Active/Inactive
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatusFlag}
                                options={statusOption}
                                onChange={(selectedOption) => {
                                    setSelectStatusFlag(selectedOption)
                                }}
                                styles={customStyles}
                                name="selectStatusFlag"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value={values.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="slug"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value={values.m_meta_title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_title"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Title"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta keywords
                        </label>
                        <div className="">
                            <input
                                value={values.m_meta_tags}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_tags"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Tags"
                            />

                        </div>



                    </div>

                
                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value={values.m_meta_desc}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_desc"
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }
        </>
    )
}

export default AddChildAttribute