import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";
import Breadcrumb from "../../../components/BreadCrumb";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import MasterServices from "../../../ApiServices/MasterServices";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";
import { wallet_columns } from "../../../components/tables/tableheader";

const Wallet = () => {
    const pages = [{ title: "Wallet", href: "/wallet", module_id: 1 }];
    const [walletData, setWalletData] = useState([]);
    const [filterWalletData,setFilterWalletData] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectAmountType, setSelectAmountType] = useState(null);
    const [selectUser, setSelectUser] = useState(null);
    const [userOption, setUserOption] = useState([])
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    useEffect(() => {
        const fetchData = async () => {
            await getAllWalletList()
            await getUsers()
        }
        fetchData()
    }, [])

    useEffect(()=>{
        if(walletData?.length>0 && userOption?.length>0){
            setLoading(false)
        }

    },[walletData,userOption])

    const customWalletData = [
        {
            eventName: "Xyz",
            email: "test@gmail.com",
            availBalance: "2000",
            amount: "222",
            status: "Y",
            date: "21/01/2024"
        }
    ]

    

    

    const amountTypeOption = [
        {
            label: "Credit",
            value: 1
        },
        {
            label: "Debit",
            value: 2
        },
    ]
  

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        //console.log("response", response?.data?.data)

        if (response?.data?.data?.length > 0) {
            const optionData = []
            

            response?.data?.data.forEach((ele) => {


                optionData.push({
                    value: ele?.u_user_id,
                    fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
                    email: ele?.u_user_email
                })

            })
            setUserOption(optionData)
        } else {
            setUserOption([])
        }
    }

    const getAllWalletList = async () => {
        try {
            const response = await MasterServices.getAllWallet();
            //console.log("getAllVoucherList",response)
            if (response?.data?.data?.length > 0) {
                const sortedData = response?.data?.data.sort((a, b) => new Date(b.m_wallet_logs_created_at) - new Date(a.m_wallet_logs_created_at));
                setWalletData(response?.data?.data);
                setFilterWalletData(response?.data?.data)
            } else {
                setWalletData([]);
                setFilterWalletData([])
            }
        }
        catch (error) {
            console.log("getAllWalletList", error)
        }
    }

    useEffect(()=>{
        if(dateInput?.startDate || dateInput?.endDate || selectAmountType ||  selectUser){
            console.log("filterWalletData",filterWalletData)
            const filterData = filterWalletData?.filter((ele)=>{
                const dateFilter = moment(ele?.m_wallet_logs_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                const statusFlag  = selectAmountType?.label.toLowerCase();
                const filterStatusValue = ele?.m_wallet_logs_type==="add"?"credit":"debit"
                const user = selectUser?.value;
           

                const userMatches = user ? parseInt(ele?.u_user_id) === parseInt(user) :true;
                const StatusMatches  = statusFlag ? filterStatusValue===statusFlag :true
                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);


                return userMatches  && StatusMatches && dateMatches;
            })
           console.log("filterData",filterData)
            if(filterData?.length>0){
                setWalletData(filterData)
            }else{
                setWalletData([])
            }
        }
    },[dateInput,selectAmountType,selectUser])


    const clearForm = () => {
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setWalletData(filterWalletData);
        setSelectUser(null);
        setSelectAmountType(null)

    }

    const downloadExcelBtn = () => {
            const filterData = walletData?.length>0&& walletData?.map((ele)=>{
                return{
                    WalletLogID:ele?.m_wallet_logs_id,
                    Email:ele?.u_user_email,
                    Type:ele?.m_wallet_logs_type==="add"?"Credit":"Debit",
                    Comments :ele?.u_comment,
                    Amount:ele?.m_amount_added,
                    Balance:ele?.u_avilable_balance,
                    CreatedAt : moment(ele?.m_wallet_logs_created_at).format("YYYY-MM-DD")
                }
            })

           downloadSheet(filterData,"wallet")
    }
    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Wallet</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Wallet.</p>
                </div>
                <Link

                    to="/add-wallet"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD WALLET AMOUNT
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            User
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                name="selectUser"
                                value={selectUser}
                                getOptionLabel={(option) => option?.fullName ? `${option?.fullName} (${option?.email})` : ""}
                                getOptionValue={(option) => option.value}

                                onChange={(selectOption) => {
                                    console.log("selectUser", selectOption)
                                    setSelectUser(selectOption)
                              
                                }
                                }
                                className="basic-single sm:text-sm"
                                options={userOption}
                                styles={customStyles}

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectAmountType}
                                options={amountTypeOption}
                                onChange={(selectedOption) => {
                                    setSelectAmountType(selectedOption)
                                }}
                                styles={customStyles}
                                name="selectAmountType"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>

                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={wallet_columns()}
                        data={walletData}
                    />
            }
        </>
    )
}

export default Wallet