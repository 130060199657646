import React, { useEffect, useState } from "react";
import { customStyles } from "../../../helper/customStyles";
import { useFormik } from "formik";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";
import Select from "react-select";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";


const AddValue = () => {

    const formValues = {
        p_attribute_value_active: "",
        p_attribute_value_name: "",
        p_product_attribute_id: "",
        p_attribute_value_id: "",
        is_storable: ""

    }

    const [initialValues, setInitialValues] = useState(formValues);
    const [loading, setLoading] = useState(true);
    const [selectStatus, setSelectStatus] = useState(null);
    const [selectStorable, setSelectStorable] = useState(null);
    const [parentDataOption, setParentDataOption] = useState([]);
    const [selectParentAttribute, setSelectParentAttribute] = useState(null)
    const [submitLoader, setSubmitLoader] = useState(false);

    const {attributeValue_id} = useParams();
    const [editValuesData,setEditValuesData] = useState([])
    const navigate = useNavigate()
    console.log("attributeValue_id",attributeValue_id)

    const statusActiveOption = [
        {
            label: "Yes",
            value: "Y"
        },
        {
            label: "No",
            value: "N"
        },
    ]

    const storableOption = [
        {
            label: "Yes",
            value: "Y"
        },
        {
            label: "No",
            value: "N"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            await getParentAttributes()
        }
        fetchData()
    }, [])

    const getParentAttributes = async () => {
        try {
            const resp = await MasterServices.getAttributes();
            console.log("getParentAttributes", resp)
            if (resp?.data?.data?.length > 0) {
                console.log("setParentDataOption", resp?.data?.data)
                setLoading(false)
                const filterData = resp?.data?.data
                    .filter(ele => ele?.p_product_attribute_active === "Y")
                    .filter(ele => ele?.p_child_attributes.length === 0)
                    .map(ele => ({
                        ...ele,
                        label: ele?.p_product_attribute_name,
                        value: ele?.p_product_attribute_id
                    }));


                setParentDataOption(filterData);
                setLoading(false)
            }

        } catch (error) {
            toast.error(error.data.message)
            setParentDataOption([]);
            setLoading(false)

        }
    }

    const getSingleAttributeValue= async(id)=>{
        try{
        
            const response = await MasterServices.getAttributeValueById(id);
            console.log("getSingleAttributeValue", response)
            if (response?.data?.data?.length > 0) {
                setEditValuesData(response?.data?.data)
            } else {
                setEditValuesData([])
            }
        }
        catch(error){
            console.log("getSingleAttributeValue",error)
        }
    }

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,

    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {

            const body={
                p_attribute_value_active: selectStatus?.value,
                p_attribute_value_name: values?.p_attribute_value_name,
                p_product_attribute_id: selectParentAttribute?.value?selectParentAttribute?.value:null,
                //is_storable: selectStorable?.value,
                p_product_attribute_value_id: attributeValue_id? parseInt(values?.p_attribute_value_id):null
            }

            console.log("body",body)
            if(attributeValue_id){
                editValueHandler(body)
            }else{
                addValuehandler(body)
            }

        }
    })

    const editValueHandler = async(payload)=>{
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editCustomValue(payload);
            console.log("editValueHandler", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/attributevalues")
            }else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("editValueHandler", error)
        }
    }

    const addValuehandler = async(payload)=>{
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addCustomValue(payload);
            console.log("addValuehandler", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/attributevalues")
            }else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("addValuehandler", error)
        }
    }

    useEffect(()=>{

        if(editValuesData?.length>0){
                editValuesData?.forEach((item)=>{
                    if(item?.p_attribute_value_name){
                        setInitialValues((prev)=>({
                            ...prev,
                            p_attribute_value_id:item?.p_attribute_value_id,
                            p_attribute_value_name:item?.p_attribute_value_name
                        }))
                    }

                    if(item?.p_attribute_value_active){
                        statusActiveOption?.forEach((ele)=>{
                            if(ele?.value===item?.p_attribute_value_active){
                                setSelectStatus({
                                    label:item?.p_attribute_value_active==="Y"?"Yes":"No",
                                    value:item?.p_attribute_value_active =="Y"?"Y":"N"
                                })
                            }
                        })
                        // if(item?.is_storable){
                        //     storableOption?.forEach((ele)=>{
                        //         if(ele?.value===item?.is_storable){
                        //             setSelectStorable({
                        //                 label:item?.is_storable==="Y"?"Yes":"No",
                        //                 value:item?.is_storable =="Y"?"Y":"N"
                        //             })
                        //         }
                        //     })
                        // }
                        if(item?.p_product_attribute_id){
                            parentDataOption?.forEach((ele)=>{
                                if(ele?.value===item?.p_product_attribute_id){
                                    setSelectParentAttribute({
                                        label:ele?.label,
                                        value:ele?.value
                                    })
                                }
                            })
                        }
                    }
                })
        }
    },[editValuesData,parentDataOption])

    useEffect(() => {
        const fetchData = async () => {

            if (attributeValue_id) {
                await getSingleAttributeValue(attributeValue_id)
            }
            
        }
        fetchData()
    }, [attributeValue_id])

    const clearForm = () => {
        navigate("/attributevalues")

    }
   

    return (
        <>

            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Values</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 "
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit()
                }}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                  
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Parent Attribute
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectParentAttribute}
                                options={parentDataOption}
                                onChange={(selectedOption) => {
                                    setSelectParentAttribute(selectedOption)
                                    
                                }}
                                styles={customStyles}
                                name="p_product_attribute_id"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Value
                        </label>
                        <div className="">
                            <input
                                value={values.p_attribute_value_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                name="p_attribute_value_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Active/Inactive
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatus}
                                options={statusActiveOption}
                                onChange={(selectedOption) => {
                                    setSelectStatus(selectedOption)
                                }}
                                styles={customStyles}
                                name="p_child_attribute_id"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Storable
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"

                                placeholder="Select"
                                value={selectStorable}
                                options={storableOption}
                                onChange={(selectedOption) => {
                                    setSelectStorable(selectedOption)
                                }}
                                styles={customStyles}
                                name="p_child_attribute_id"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div> */}

                </div>

                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >

                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }
        </>
    )
}

export default AddValue