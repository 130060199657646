import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../../../helper/customStyles";
import Breadcrumb from "../../../components/BreadCrumb";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import MasterServices from "../../../ApiServices/MasterServices";
import { vocuher_columns } from "../../../components/tables/tableheader";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";


const Voucher = () => {
    const pages = [{ title: "Voucher", href: "/voucher", module_id: 1 }];
    const [loading, setLoading] = useState(true);
    const [voucherListData,setVoucherListData] = useState([]);
    const [filterVoucherData,setFilterVoucherData] = useState([])
    const [selectStatus,setSelectStatus] = useState(null);
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    useEffect(()=>{
        const fetchData = async()=>{
            await getAllVoucherList()
        }
        fetchData()
    },[])

    const statusOption = [
        {
            label:"Active",
            value:"Y"
        },
        {
            label:"Inactive",
            value:"N"
        },
    ]

    const getAllVoucherList  = async()=>{
        try{
            const response  = await MasterServices.getAllVoucher();
            //console.log("getAllVoucherList",response)
            if(response?.data?.data?.length>0){

                setVoucherListData(response?.data?.data);
                setFilterVoucherData(response?.data?.data);
                setLoading(false)
                
            }else{
                setVoucherListData([]) ;
                setFilterVoucherData([]);
                setLoading(false)
            }
        }
        catch(error){
            console.log("getAllVoucherList",error)
        }
    }

    useEffect(()=>{
       
        if(dateInput?.startDate || dateInput?.endDate || selectStatus){

            const filterData = filterVoucherData?.filter((ele)=>{
                    const dateFilter =ele?.created_at ?  moment(ele?.created_at).format("YYYY-MM-DD"):null;
                    const startDate = dateInput?.startDate ?  moment(dateInput?.startDate).format("YYYY-MM-DD"):null;
                    const endDate = dateInput?.endDate ?  moment(dateInput?.endDate).format("YYYY-MM-DD"):null;
                    console.log("dateInput",{
                        dateFilter,
                        startDate
                    })
                    const statusFlag = selectStatus?.value ? selectStatus?.value:null

                    const statusMatches = statusFlag ? ele?.is_active ===statusFlag :true
                    const dateMatches = (!startDate || startDate <=dateFilter) && (!endDate || endDate >=dateFilter);


                    return statusMatches && dateMatches
                    
            })
            console.log("filterVoucherData",{
                filterVoucherData,
                filterData
            })
            if(filterData?.length>0){
                setVoucherListData(filterData)
            }
            else{
                setVoucherListData([])
            }
        }
    },[dateInput,selectStatus])

    const clearForm = ()=>{
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setSelectStatus(null)
        setVoucherListData(filterVoucherData);

    }
   
    const downloadExcelBtn = ()=>{
        const filterData = voucherListData?.map((ele)=>{
            return{
                ID:ele?.m_voucher_id,
                VoucherCode:ele?.m_voucher_code,
                Status:ele?.is_active,
                ExpiryDate:moment(ele?.ele?.m_expiry_date).format("YYYY-MM-DD"),
                CreatedAt : moment(ele?.created_at).format("YYYY-MM-DD")
            }
        })

       downloadSheet(filterData,"voucher")
    }
    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Voucher</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Voucher.</p>
                </div>
                <Link

                    to="/add-voucher"
                    className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    ADD VOUCHER
                </Link>
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatus}
                                options={statusOption}
                                onChange={(selectedOption)=>{
                                    setSelectStatus(selectedOption)
                                }}
                                styles={customStyles}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                           
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    startDate:e.target.value
                                   }))
                                }}
                              
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    endDate:e.target.value
                                   }))
                                }}
                                
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    
                </div>
                <div className=" pt-4 ">
                    <button
                         onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading?
                <Spinner />
                :
            <Table
                columns={vocuher_columns()}
                data={voucherListData}
            />
            }
        </>
    )
}


export default Voucher
