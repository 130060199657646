export const CurrencyData = () => {
    let dataFromRedux 


    if(typeof window !== "undefined"){
     let localStoragedata  =  localStorage.getItem('currencyConversionData')
     dataFromRedux = JSON.parse(localStoragedata)
    }
    
    return dataFromRedux
};


export const global_epp = (epp_price = 0, sb_currency = "INR ₹") => {
  //console.log("sb_currency",sb_currency)
    let currencyData = CurrencyData()
  
    var price  = epp_price; //parseFloat(epp_price);
    var show_og_price = "";
  
    if (sb_currency === "USD $") {
      var converted_price = price * currencyData?.USD;
      var formatted_price = format_price(converted_price);
      show_og_price = "$ " + formatted_price;
    } else if (sb_currency === "GBP £") {
      //console.log("jay>>>>>>>>>")
      var converted_price = price * currencyData?.GBP;
      var formatted_price = format_price(converted_price);
      show_og_price = "£ " + formatted_price;
    } else {
      var formatted_price = format_inr(parseInt(price));
      show_og_price = "₹ " + formatted_price;
    }
  
    return show_og_price;
  };

  export const global_epp_normal = (epp_price = 0, sb_currency = "INR ₹") => {
    //console.log("sb_currency",sb_currency)
    let currencyData = CurrencyData()
  
    var price  = epp_price; //parseFloat(epp_price);
    var show_og_price = "";
  
    if (sb_currency === "USD $") {
      var converted_price = price * currencyData?.USD;
      var formatted_price = format_price(converted_price);
      show_og_price = formatted_price;
    } else if (sb_currency === "GBP £") {
      //console.log("jay>>>>>>>>>")
      var converted_price = price * currencyData?.GBP;
      var formatted_price = format_price(converted_price);
      show_og_price =  formatted_price;
    } else {
      show_og_price = price;
    }
  
    return show_og_price;
  };

  export const global_epp_revrse = (epp_price = 0, sb_currency = "INR ₹") => {
    //console.log("sb_currency",sb_currency)
    let currencyData = CurrencyData()
  
    var price  = epp_price; //parseFloat(epp_price);
    var show_og_price = null;
  
    if (sb_currency === "USD $") {
      var converted_price = price / currencyData?.USD;
      var formatted_price = format_price(converted_price);
      show_og_price = parseFloat(formatted_price).toFixed(2);
    } else if (sb_currency === "GBP £") {
      //console.log("jay>>>>>>>>>")
      var converted_price = price / currencyData?.GBP;
      var formatted_price = format_price(converted_price);
      show_og_price = parseFloat(formatted_price).toFixed(2);
    } else {
      var formatted_price = format_inr(parseInt(price));
      show_og_price = parseFloat(formatted_price).toFixed(2);
    }
  
    //console.log(show_og_price, "show_og_price ????????????")
    return show_og_price;
  };

  function format_price(num) {
    return num?.toFixed(2).replace(/(\.0+|0+)$/, ""); // Remove trailing zeros
  }

  function format_inr(num) {
    var numStr = num?.toFixed(2).replace(/(\.0+|0+)$/, ""); // Convert number to string and remove trailing zeros
    var parts = numStr.split(".");
    var beforePoint = parts[0];
    var afterPoint = parts[1] ? "." + parts[1] : "";
  
    var lastThree = beforePoint.slice(-3);
    var restUnits = beforePoint.slice(0, -3);
  
    if (restUnits !== "") {
      lastThree = "," + lastThree;
    }
  
    restUnits = restUnits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    var formatted = restUnits + lastThree + afterPoint;
  
    return formatted;
  }
  