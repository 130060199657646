import React, { useState,useEffect } from "react";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import { useFormik } from "formik";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";


const AddParentAttribute = () => {

    const storableOption = [
        { value: 'Y', label: 'Yes' },
        { value: 'N', label: 'No' }
    ];

    const options1 = [
        { value: 'Currency', label: 'Currency' }

    ];


    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const {parent_id} = useParams();
    const [editParentData,setEditParentData] = useState([]);
    const [selectIsStorable,setSelectIsStorable] = useState({});
    const [selectStatusFlag,setSelectStatusFlag] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false)
    const [data, setdata] = useState({
        p_product_attribute_name: null,
        p_product_attribute_value: null,
        is_storable: "",
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null
    });

    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];

    const [initialValues,setInitialValues]  = useState({
        p_product_attribute_name: null,
        is_storable: "",
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null
    })

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            const value = inputValue.trim();
            if (!items.includes(value)) {
                setItems([...items, value]);
            }
            setInputValue('');
        }
    };

    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setdata((data) => ({ ...data, [e.target.name]: e.target.value }))
    // }

    const handleDropdown = (val) => {

        console.log(val)

        setdata(data => ({ ...data, is_storable: val.value }))

    }

    const handleDropdown1 = (val) => {

        // console.log(val)

        setdata(data => ({ ...data, p_product_attribute_value: val.value }))

    }
    const getSingleParentAttribute = async(id)=>{
        try {
            const response = await MasterServices.getParentAttributeById(id);
            console.log("getSingleParentAttribute", response)
            if (response?.data?.data?.length > 0) {
                setEditParentData(response?.data?.data)
            } else {
                setEditParentData([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {
            const body = {
                p_product_attribute_name: values.p_product_attribute_name,
                is_storable: selectIsStorable?.value ?selectIsStorable?.value:null,
                slug: values.slug,
                m_meta_title: values.m_meta_title,
                m_meta_desc: values.m_meta_desc,
                m_meta_tags: values.m_meta_tags,
                p_product_attribute_id:parent_id?parseInt(parent_id):null,
                p_product_attribute_active:selectStatusFlag?.value ? selectStatusFlag?.value : null,
                
            }

            console.log("body", body)
            if (editParentData?.length > 0) {
                editParentAttribute(body)
            } else {
                addParentAttribute(body)
            }

        }
    })

    const editParentAttribute = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editProductAttribute(payload);
            console.log("editParentAttribute", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/parentattribute")
            }else{
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            toast.error(error?.data?.message)
            setSubmitLoader(false)
            console.log("editParentAttribute", error)
        }
    }

    const addParentAttribute = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addProductAttribute(payload);
            console.log("addParentAttribute", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/parentattribute")
            }else{
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
            console.log("addParentAttribute", error)
        }
    }

    useEffect(()=>{

        if(editParentData?.length>0){
            editParentData.forEach((item)=>{
                if(item?.p_product_attribute_name){
                    setInitialValues({
                        p_product_attribute_name: item?.p_product_attribute_name,
                    })
                }

                if(item?.p_product_attribute_active){
                    statusOption?.forEach((ele)=>{
                        if(item?.p_product_attribute_active===ele?.value){
                            setSelectStatusFlag({
                                label:ele.label,
                                value:ele.value
                            })
                        }
                    })
                }

                if(item?.p_is_storable){
                    storableOption?.forEach((ele)=>{
                        if(ele?.value===item?.p_is_storable){
                            setSelectIsStorable({
                                label:ele.label,
                                value:ele.value
                            })
                        }
                    })
                }
                
            })
          
        }
    },[editParentData])


    // const submitform = async () => {
    //     let finaldata = data;
    //     finaldata = { ...finaldata, items }
    //     let resp = await MasterServices.addProductAttribute(finaldata)
    //     if (resp.data.success) {
    //         console.log(resp.data)
    //         setdata({
    //             "p_product_attribute_name": null,
    //             "p_product_attribute_value": null,
    //             "is_storable": "",
    //             "slug": null,
    //             "m_meta_title": null,
    //             "m_meta_desc": null,
    //             "m_meta_tags": null
    //         });
    //         setItems([]);
    //         setInputValue("")
    //     }
    //     else {
    //         console.log(resp.data)
    //     }
    // }

    const clearForm  =()=>{
        navigate("/parentattribute")
    }

    useEffect(() => {
        const fetchData = async () => {
            if (parent_id) {
                await getSingleParentAttribute(parent_id)
            }
        }
        fetchData()
    }, [parent_id])

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{parent_id?"Edit Parent Attribute":"Add Parent Attribute"}</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="">
                            <input
                                value={values.p_product_attribute_name}
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                name="p_product_attribute_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>

                    {/* <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Type
                        </label>
                        <div className="">
                           

                            <Select
                                onChange={handleDropdown1}
                                classNamePrefix="select"
                                placeholder="Select"
                                name="p_product_attribute_value"
                                className="basic-single sm:text-sm"
                                options={options1}
                                styles={customStyles}
                            />

                        </div>
                    </div> */}

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Is storable
                        </label>
                        <div>
                            
                             <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectIsStorable}
                                options={storableOption}
                                onChange={(selectedOption) => {
                                    setSelectIsStorable(selectedOption)
                                }}
                                styles={customStyles}
                                name="selectIsStorable"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Active/Inactive
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatusFlag}
                                options={statusOption}
                                onChange={(selectedOption) => {
                                    setSelectStatusFlag(selectedOption)
                                }}
                                styles={customStyles}
                                name="selectStatusFlag"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div>

                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value={values.m_meta_title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_title"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Title"
                            />

                        </div>
                    </div>

                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Tags
                        </label>
                        <div className="">
                            <input
                                value={values.m_meta_tags}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_tags"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Tags"
                            />

                        </div>
                    </div>
                    <div className="">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value={values.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="slug"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>

                    {/* <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Values
                        </label>
                        <div className="">

                            <div className="relative flex flex-wrap items-center">
                                {items.map((item, index) => (
                                    <div key={index} className="flex items-center sm:text-sm bg-gray-200 rounded px-2 py-1 m-1">
                                        <span>{item}</span>
                                        <button className="ml-1 sm:text-sm text-red-500 hover:text-red-700" onClick={() => removeItem(index)}>&times;</button>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    className="flex-grow rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Type and Press Enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>

                        </div>


                    </div> */}
                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value={values.m_meta_desc}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_desc"
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                </div>
                {/* <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200">
                    <div className="col-start-0 col-end-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value={values.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="slug"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>
                    <div className="col-start-0 col-end-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Thumbnail
                        </label>
                        <div className="flex">
                            <div className="flex items-center  justify-center h-full w-full">
                                <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                    <span className="text-md text-gray-500">* Front Image</span>

                                    <input type="file" class="hidden"
                                    //onChange={(e) => handleSingleImageUpload(e, setSingleImage)}
                                    />

                                </label>
                            </div>
                        </div>
                    </div>

                </div> */}
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                         type="button"
                         onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </div >
        </>
    )
}

export default AddParentAttribute