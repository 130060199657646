import React, { useEffect, useState } from "react";
import { PlusCircleIcon, ArrowDownCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Select from "react-select"
import { currencyStyles, customStyles } from "../../../helper/customStyles";
const BillingAddressComp = (
    {
        values,
        billingFlag,
        setBillingFlag,
        loadBillingHandle,
        handleChange,
        errors,
        touched,
        country,
        setCountry,
        setFieldValue,
        State,
        Country,
        setState,
        state,
        selectPaymentMethod,
        setSelectPaymentMethod,
        paymentMethod,
        viewFlag

    }) => {
    return (

        <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">

            <div className="flex justify-between items-center">
                <label className="text-black text-lg font-medium">Billing Address</label>
                <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setBillingFlag((prev) => !prev)} /></div>
            </div>
            <div className={`${billingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                <h4 className="text-sm text-[#0094FF] cursor-pointer" onClick={() => {
                    if (!viewFlag) {
                        loadBillingHandle()
                    }
                }
                }>
                    Load Billing Address
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-start">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            First Name
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_firstName}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                // onBlur={handleBlur}
                                type="text"
                                name="b_firstName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="First Name"
                            />
                            {errors.b_firstName && touched.b_firstName && (
                                <div className="text-red-600 text-sm">{errors.b_firstName}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Last Name
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_lastName}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_lastName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Last Name"
                            />
                            {errors.b_lastName && touched.b_lastName && (
                                <div className="text-red-600 text-sm">{errors.b_lastName}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Company Name
                        </label>
                        <div className="">
                            <input
                                value={values.b_companyName}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_companyName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Company Name"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Address line 1
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_addressOne}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_addressOne"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Address 1"
                            />
                            {errors.b_addressOne && touched.b_addressOne && (
                                <div className="text-red-600 text-sm">{errors.b_addressOne}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Address line 2
                        </label>
                        <div className="">
                            <input
                                value={values.b_addressTwo}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_addressTwo"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Address 2"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            City
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_city}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_city"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="City"
                            />
                            {errors.b_city && touched.b_city && (
                                <div className="text-red-600 text-sm">{errors.b_city}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Postcode / Zip
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_zipCode}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_zipCode"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="zipCode"
                            />
                            {errors.b_zipCode && touched.b_zipCode && (
                                <div className="text-red-600 text-sm">{errors.b_zipCode}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Country / Region
                        </label>
                        <div className="flex flex-col">
                            <Select
                                classNamePrefix="select"
                                isDisabled={viewFlag ? true : false}
                                placeholder="Select"
                                value={country}
                                getOptionLabel={(options) => {
                                    return options["name"];
                                }}
                                getOptionValue={(options) => {
                                    return options["name"];
                                }}
                                onChange={(selectOption) => {
                                    setCountry(selectOption)
                                    //console.log("setCountry", selectOption)
                                    setFieldValue('b_country', selectOption ? selectOption.name : '');

                                }}
                                name="b_country"
                                className="basic-single sm:text-sm"
                                options={Country?.getAllCountries()}
                                styles={customStyles}
                            />
                            {errors.b_country && touched.b_country && (
                                <div className="text-red-600 text-sm">{errors.b_country}</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            State
                        </label>
                        <div>

                            <Select
                                classNamePrefix="select"
                                placeholder="Select"
                                value={state}
                                isDisabled={viewFlag ? true : false}
                                onChange={(selectOption) => {
                                    setState(selectOption)

                                }}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                options={State?.getStatesOfCountry(
                                    (country)?.isoCode
                                )}
                                getOptionLabel={(options) => {
                                    return options["name"];
                                }}
                                getOptionValue={(options) => {
                                    return options["name"];
                                }}
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_email}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="email"
                                name="b_email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Email"
                            />
                            {errors.b_email && touched.b_email && (
                                <div className="text-red-600 text-sm">{errors.b_email}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="flex flex-col">
                            <input
                                value={values.b_phone}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                name="b_phone"
                                min="0"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Phone"
                            />
                            {errors.b_phone && touched.b_phone && (
                                <div className="text-red-600 text-sm">{errors.b_phone}</div>
                            )}


                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Payment Method
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectPaymentMethod}
                                isDisabled={viewFlag ? true : false}

                                // // onBlur={handleBlur}
                                onChange={(selectOption) => {
                                    setSelectPaymentMethod(selectOption);
                                    setFieldValue('paymentMethod', selectOption ? selectOption.value : '');
                                }}
                                name="paymentMethod"
                                className="basic-single sm:text-sm"
                                options={paymentMethod}
                                styles={customStyles}
                            />
                            {errors.paymentMethod && touched.paymentMethod && (
                                <div className="text-red-600 text-sm">{errors.paymentMethod}</div>
                            )}

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Transaction Id
                        </label>
                        <div className="">
                            <input
                                value={values.b_transactionId}
                                disabled={viewFlag ? true : false}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                type="text"
                                min="0"
                                name="b_transactionId"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Transaction Id"
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingAddressComp