import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";
import { IMAGEURL } from "../../../helper/constant";
import UploadFileComp from "../../../components/UploadFileComp";
import MasterServices from "../../../ApiServices/MasterServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toast";
import { customStyles } from "../../../helper/customStyles";


const AddCategory = () => {

    const formValues = {
        p_category_name: "",

        p_category_desc: "",
        p_category_active: "Y",

        m_meta_title: "",
        m_meta_tags: "",
        m_meta_dasc: "",
        m_route_value: "",

        m_item_id: null,
        m_route_id: null,
        m_route_status: ""

    }
    const [initialValues, setInitialValues] = useState(formValues);
    const [selectParentCategory, setSelectParentCategory] = useState(null);
    const [parentCategoryOption, setParentCategoryOption] = useState([]);
    const [singleImage, setSingleImage] = useState(null);
    const [editCategory, setEditCategory] = useState([]);
    const [singleImageProgress, setSingleImageProgress] = useState(0);
    const [categoryId, setCategoryId] = useState(null);
    const [parentCategoryUpdated, setParentCategoryUpdated] = useState(null)
    const [subCategoryID, setSubCategoryId] = useState(null);
    const [typeOfCategory, setTypeOfCategory] = useState(null);
    const [parentID, setParentId] = useState(null);
    const [selectStatusFlag, setSelectStatusFlag] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false)

    const navigate = useNavigate();
    const { category_id } = useParams();
    const locationUrl = useLocation();
    console.log("locationUrl", locationUrl)

    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];

    useEffect(() => {
        const fetchData = () => {
            getParentCategory()
        }
        fetchData()
    }, [])

    useEffect(() => {

    }, [])


    const getParentCategory = async () => {
        try {

            const response = await MasterServices.getAllCategory();
            console.log("getCategory", response)
            if (response?.data?.data?.length > 0) {
                console.log("setParentCategoryOption", response?.data?.data)
                const filter = response?.data?.data
                    .filter(ff => ff?.is_category_active === "Y")
                    .map((ele) => ({
                        label: ele?.p_category_name,
                        value: ele?.category_id
                    }))
                // setParentCategoryOption(filter)

                //const optionsWithNone = [{ value: null, label: "None" }, ...filter];
                setParentCategoryOption(filter)

            }
        } catch (error) {
            toast.error("error in getParentCategory")
        }
    }

    const getCategoryById = async (id) => {
        try {

            const response = await MasterServices.getSingleCategory(id);
            console.log("getCategoryById", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditCategory(response?.data?.data)

            }
        }
        catch (error) {
            toast.error(error)
        }

    }
    const getSubCategoryById = async (id) => {
        try {

            const response = await MasterServices.getSingleSubCategory(id);
            console.log("getSubCategoryById", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditCategory(response?.data?.data);

            }
        }
        catch (error) {
            toast.error(error)
        }

    }
    useEffect(() => {
        const fetchData = async () => {
            if (locationUrl.pathname.includes("editcategory")) {

                await getCategoryById(category_id)

            } else {
                await getSubCategoryById(category_id)
            }
        }
        fetchData()
    }, [category_id])


    useEffect(() => {

        if (editCategory?.length > 0 && category_id) {
            console.log("editCategory", {
                editCategory,
                parentCategoryOption
            })
            editCategory.forEach((item) => {

                if (item?.p_product_sub_category_id) {
                    setInitialValues({
                        p_category_name: item?.p_sub_category_name,
                        p_category_desc: item?.p_sub_category_desc,
                        p_category_active: item?.p_sub_category_active,


                        m_meta_title: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_title : "",
                        m_meta_tags: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_tags : "",
                        m_meta_dasc: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_dasc : "",

                        m_item_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_item_id : null,
                        m_route_value: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_value : "",
                        m_route_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_id : null,
                        m_route_status: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_status_active : "",

                    })
                    setSingleImage(item?.p_sub_category_image_1)
                    setSelectParentCategory(item?.p_category_id);
                    setParentId(item?.p_category_id);
                    setTypeOfCategory("ChildCategory")

                    if (item?.p_category_id) {
                        parentCategoryOption?.forEach((ele) => {
                            if (ele?.value === item?.p_category_id) {
                                setSelectParentCategory({
                                    label: ele?.label,
                                    value: ele?.value
                                })
                            }
                        })
                    }
                    if (item?.p_sub_category_active) {
                        statusOption?.length > 0 && statusOption.forEach((ele) => {
                            if (ele?.value === item?.p_sub_category_active) {
                                setSelectStatusFlag({
                                    label: ele?.label,
                                    value: ele?.value
                                })
                            }
                        })
                    }
                } else {
                    setInitialValues({
                        p_category_name: item?.p_category_name,
                        p_category_desc: item?.cat_name,
                        p_category_active: item?.p_category_active,


                        m_meta_title: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_title : "",
                        m_meta_tags: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_tags : "",
                        m_meta_dasc: item?.metadata?.length > 0 ? item?.metadata[0]?.m_meta_dasc : "",

                        m_item_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_item_id : null,
                        m_route_value: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_value : "",
                        m_route_id: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_id : null,
                        m_route_status: item?.metadata?.length > 0 ? item?.metadata[0]?.m_route_status_active : "",

                    })
                    setSingleImage(item?.p_category_image_1)
                    setSelectParentCategory(null);
                    setParentId(null);
                    setTypeOfCategory("ParentCategory")

                    if (item?.p_category_name) {
                        const filterData = parentCategoryOption?.length > 0 && parentCategoryOption?.filter((ele) => ele?.label !== item?.p_category_name);
                        setParentCategoryOption(filterData);
                    }

                    if (item?.p_category_active) {
                        statusOption?.length > 0 && statusOption.forEach((ele) => {
                            if (ele?.value === item?.p_category_active) {
                                setSelectStatusFlag({
                                    label: ele?.label,
                                    value: ele?.value
                                })
                            }
                        })
                    }
                }



                setCategoryId(item?.category_id)
                setParentCategoryUpdated(item?.p_category_name)

            })

        }
    }, [editCategory])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {
            const body = {
                ...values,
                p_parent_category_id: selectParentCategory?.value ? selectParentCategory?.value : null,
                p_category_image_1: singleImage ? singleImage : null,
                p_category_id: categoryId ?? category_id,
                p_category_id_old: selectParentCategory?.value ? categoryId : null,

                parentID: editCategory?.length > 0 ? parentID : null,
                typeOfCategory: editCategory?.length > 0 ? typeOfCategory : null,
                p_category_active: selectStatusFlag?.value ? selectStatusFlag?.value : null


            }

            console.log("body", body)
            if (editCategory?.length > 0) {
                editCatgeoryButton(body)
            } else {
                addCategoryButton(body)
            }

        }
    })

    const editCatgeoryButton = async (body) => {
        console.log("editCatgeoryButton", body)
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editCategory(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("category Edit Successfully");
                navigate("/category-page")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while Edit category");
        }
    }

    const addCategoryButton = async (body) => {
        console.log("addCategoryButton", body);
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addCategory(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("category Added Successfully");
                navigate("/category-page")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while adding the category");
        }


    }

    const clearForm = () => {
        navigate("/category-page")

    }

    const handleFileChange = async (e, setImageState) => {
        const file = e.target.files[0];
        if (file) {

            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        e.target.value = ''; // Clear the file input
    }


    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">
                    {locationUrl.pathname.includes("/editSubCategory") ? "Edit SubCategory" : locationUrl.pathname.includes("/editcategory") ? "Edit Category" : "Add Category"}
                </h1>
            </div>
            <form className="py-5 flex flex-col gap-5 " onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="border-b-[1px] border-[#ebac78] p-5">
                        <h1 className="text-md font-semibold text-black">Category Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="">
                                <input
                                    value={values.p_category_name}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="p_category_name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Name"
                                />

                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Parent Category
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectParentCategory}
                                    onChange={(selectedOption) => {
                                        setSelectParentCategory(selectedOption)
                                    }}
                                    options={parentCategoryOption}
                                    name="p_parent_category_id"
                                    className="basic-single sm:text-sm"
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Active/Inactive
                            </label>
                            <div>

                                <Select
                                    classNamePrefix="select"
                                    //defaultValue={userTypes[0]}
                                    placeholder="Select"
                                    value={selectStatusFlag}
                                    options={statusOption}
                                    onChange={(selectedOption) => {
                                        setSelectStatusFlag(selectedOption)
                                    }}
                                    styles={customStyles}
                                    name="selectStatusFlag"
                                    className="basic-single sm:text-sm"

                                />

                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.p_category_desc}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="p_category_desc"
                                    className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Description"
                                />

                            </div>
                        </div>
                        <div className="sm:col-span-1 w-full">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Thumbnail
                            </label>

                            <div className=" ">
                                {
                                    singleImage ?
                                        <div className="flex items-center justify-center w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200">
                                            <div className="relative">
                                                <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                    <img src={`${IMAGEURL}${singleImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                            onClick={() => setSingleImage(null)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="flex items-center justify-center w-full">
                                            {
                                                parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                    <div className="w-full h-60 flex justify-center items-center">
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                        <span className="text-md text-gray-500">* Upload Image</span>
                                                        <input type="file" class="hidden" accept="image/*" onChange={(e) => handleFileChange(e, setSingleImage)} />

                                                    </label>
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200 ">
                    <div className=" p-5 border-b-[1px] border-[#ebac78] ">
                        <h1 className="text-md font-semibold text-black">Meta Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 shadow-sm ">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Title
                            </label>
                            <div className="">
                                <input
                                    value={values.m_meta_title}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_title"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Title"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta keywords
                            </label>
                            <div className="">
                                <input
                                    value={values.m_meta_tags}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_tags"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta keywords"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Slug
                            </label>
                            <div className="">
                                <input
                                    value={values.m_route_value}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_route_value"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Slug"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Meta Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.m_meta_dasc}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    type="text"
                                    name="m_meta_dasc"
                                    className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Meta Description"
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-end  ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="button"
                        onClick={() => handleSubmit()}
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddCategory