import React, { useEffect, useState } from "react";
import { PlusCircleIcon, ArrowDownCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Select from "react-select"
import { currencyStyles, customStyles } from "../../../helper/customStyles";
const ShippingAddressComp = (
    {
        shippingFlag,
        setShippingFlag,
        loadShippingHandle,
        copyBillingHandle,
        shippingValues,
        setShippingValues,
        handleChange,
        errors,
        touched,
        shipCountry,
        setShipCountry,
        shipState,
        setShipState,
        setFieldValue,
        Country,
        State,
        displayShippingPrice,
        setDisplayShippingPice,
        custShippingFlag,
        applyCustomShippingAmount,
        removeCustomShippingAmount,
        viewFlag

    }
) => {
    return (
        <>
            <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg text-black font-medium">Shipping Address</h2>
                    <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShippingFlag((prev) => !prev)} /></div>
                </div>

                <div className={`${shippingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                    <div className="flex gap-5">
                        <h4 className="text-sm text-[#0094FF] cursor-pointer"
                            onClick={() => {
                                if (!viewFlag) {
                                    loadShippingHandle()
                                }
                            }
                            }>Load Shipping Address</h4>
                        <h4 className="text-sm text-[#0094FF] cursor-pointer"
                            onClick={() => {
                                if (!viewFlag) {
                                    copyBillingHandle()
                                }
                            }
                            }
                        >Copy Billing Address</h4>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-start">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.firstName}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        handleChange(e)
                                        //setFirstName(e.target.value)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            firstName: e.target.value
                                        }))
                                    }}

                                    type="text"
                                    name="s_firstName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="First Name"
                                />
                                {errors?.s_firstName && touched?.s_firstName && (
                                    <div className="text-red-600 text-sm">{errors?.s_firstName}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.lastName}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        //setLastName(e.target.value)
                                        handleChange(e)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            lastName: e.target.value
                                        }))

                                    }}


                                    type="text"
                                    name="s_lastName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Last Name"
                                />
                                {errors.s_lastName && touched.s_lastName && (
                                    <div className="text-red-600 text-sm">{errors.s_lastName}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Company Name
                            </label>
                            <div className="flex flex-col">
                                <input

                                    value={shippingValues?.companyName}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {

                                        //setCompanyName(e.target.value)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            companyName: e.target.value
                                        }))
                                    }}
                                    type="text"
                                    name="companyName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Company Name"
                                />


                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Address line 1
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.addressOne}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        handleChange(e)
                                        //setAddressOne(e.target.value)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            addressOne: e.target.value
                                        }))

                                    }}

                                    type="text"
                                    name="s_addressOne"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Address 1"
                                />
                                {errors.s_addressOne && touched.s_addressOne && (
                                    <div className="text-red-600 text-sm">{errors.s_addressOne}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Address line 2
                            </label>
                            <div className="">
                                <input
                                    value={shippingValues?.addressTwo}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        //setAddressTwo(e.target.value)

                                        setShippingValues((prev) => ({
                                            ...prev,
                                            addressTwo: e.target.value
                                        }))
                                    }
                                    }
                                    type="text"
                                    name="addressTwo"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Address 2"
                                />

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                City
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.city}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        handleChange(e)
                                        //setCity(e.target.value)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            city: e.target.value
                                        }))

                                    }}

                                    type="text"
                                    name="s_city"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="City"
                                />
                                {errors.s_city && touched.s_city && (
                                    <div className="text-red-600 text-sm">{errors.s_city}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Postcode / Zip
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.zipCode}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        handleChange(e)

                                        setShippingValues((prev) => ({
                                            ...prev,
                                            zipCode: e.target.value
                                        }))
                                    }}

                                    type="text"
                                    name="s_zipCode"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"

                                />
                                {errors.s_zipCode && touched.s_zipCode && (
                                    <div className="text-red-600 text-sm">{errors.s_zipCode}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Country / Region
                            </label>
                            <div className="flex flex-col">

                                <Select
                                    classNamePrefix="select"
                                    isDisabled={viewFlag ? true : false}
                                    placeholder="Select"
                                    value={shipCountry}
                                    name="s_country"
                                    className="basic-single sm:text-sm"
                                    options={Country.getAllCountries()}
                                    getOptionLabel={(options) => {
                                        return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["name"];
                                    }}
                                    onChange={(selectOption) => {
                                        setShipCountry(selectOption)
                                        setFieldValue('s_country', selectOption ? selectOption.name : '');
                                        // console.log("setShipCountry", selectOption)
                                        // setCustomTouched((prevTouched) => ({
                                        //     ...prevTouched,
                                        //     s_country: true,
                                        // }));

                                    }}

                                    styles={customStyles}
                                />
                                {errors.s_country && touched.s_country && (
                                    <div className="text-red-600 text-sm">{errors.s_country}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                State
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={shipState}
                                    name="userStatus"
                                    styles={customStyles}
                                    isDisabled={viewFlag ? true : false}
                                    onChange={(selectOption) => {
                                        setShipState(selectOption)

                                    }}
                                    className="basic-single sm:text-sm"
                                    options={State?.getStatesOfCountry(
                                        (shipCountry)?.isoCode
                                    )}
                                    getOptionLabel={(options) => {
                                        return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["name"];
                                    }}
                                />

                            </div>
                        </div>

                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Phone
                            </label>
                            <div className="flex flex-col">
                                <input
                                    value={shippingValues?.phone}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        //setPhone(e.target.value)
                                        handleChange(e)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            phone: e.target.value
                                        }))

                                    }}


                                    type="text"
                                    min="0"
                                    name="s_phone"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Phone"
                                />
                                {errors.s_phone && touched.s_phone && (
                                    <div className="text-red-600 text-sm">{errors.s_phone}</div>
                                )}

                            </div>
                        </div>

                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Customer Provider Note
                            </label>
                            <div className="">
                                <input
                                    value={shippingValues?.customerProviderNote}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => {
                                        //setCustomerProviderNote(e.target.value)
                                        setShippingValues((prev) => ({
                                            ...prev,
                                            customerProviderNote: e.target.value
                                        }))
                                    }}
                                    type="text"

                                    name="customerProviderNote"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Customer Provider Note"
                                />

                            </div>
                        </div>
                        <div className="">
                            <div className="flex ">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                                    Custom Shipping Price
                                </label>
                            </div>
                            <div className="flex gap-2">
                                {/* <div className=""> */}
                                <input
                                    value={displayShippingPrice}
                                    disabled={viewFlag ? true : false}
                                    onChange={(e) => { setDisplayShippingPice(e.target.value) }}
                                    type="number"
                                    min="0"
                                    name="shippingPrice"
                                    className="block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Shipping Price"
                                />

                                {/* </div> */}
                                <button type="button"
                                    disabled={viewFlag ? true : false}
                                    className={`${viewFlag ? "text-[#fff] bg-gray-400 cursor-not-allowed" : "text-white bg-[#3a4b43]"} rounded-lg  px-4 py-2  text-sm font-semibold  `}
                                    onClick={() => {
                                        if (custShippingFlag) {
                                            applyCustomShippingAmount()
                                        } else {
                                            removeCustomShippingAmount()

                                        }

                                    }}>
                                    {
                                        custShippingFlag ? "Apply" : "Remove"
                                    }
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default ShippingAddressComp