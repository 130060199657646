import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import { customStyles } from "../../../helper/customStyles";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { abandoned_columns } from "../../../components/tables/tableheader";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";

const Abandoned = () => {
    const pages = [{ title: "Abandoned", href: "/abandoned", module_id: 1 }];
    const [loading, setLoading] = useState(true);
    const [abandonedData,setAbandonData] = useState([]);
    const [filterAbandonData,setFilterAbandonData] = useState([]);
    const [selectUserType,setSelectUserType] = useState(null)
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    const userType = [
        {
            label:"Customer",
            value:1,
        },
        {
            label:"Guest",
            value:2,
        },
    ]

   

    // const abandonedData = [{
    //     customer:"abc",
    //     email:"test@gmail.com",
    //     phone:"222777",
    //     sku:"ABBAB",
    //     cartValue:2000
    // }]

    useEffect(()=>{
        const fetchData= async()=>{
            await getAbandonList()
        }
        fetchData()
    },[])


    const getAbandonList = async()=>{
        try{
            const response = await MasterServices.getAbandonedList();
            console.log("getAbandonList",response?.data?.data)
            if(response?.data?.data?.length>0){
                const sortedData = response?.data?.data.sort((a, b) => new Date(b.first_sent_at) - new Date(a.first_sent_at));
                setAbandonData(sortedData);
                setFilterAbandonData(sortedData)
                setLoading(false)
            }else{
                setAbandonData([]);
                setFilterAbandonData([])
                setLoading(false)
            }
        }
        catch(error){
            console.log("getAbandonList",error)
        }
    }

    useEffect(()=>{
       
        if(dateInput?.startDate || dateInput?.endDate || selectUserType){

            const filterData = filterAbandonData?.filter((ele)=>{
                    const dateFilter =ele?.first_sent_at ?  moment(ele?.first_sent_at).format("YYYY-MM-DD"):null;
                    const startDate = dateInput?.startDate ?  moment(dateInput?.startDate).format("YYYY-MM-DD"):null;
                    const endDate = dateInput?.endDate ?  moment(dateInput?.endDate).format("YYYY-MM-DD"):null;
                    
                    const statusFlag = selectUserType?.label ? selectUserType?.label:null
                    const checkStatus = ele?.user_ip?"Guest":"Customer"
                    const statusMatches = statusFlag ? checkStatus ===statusFlag :true
                    const dateMatches = (!startDate || startDate <=dateFilter) && (!endDate || endDate >=dateFilter);


                    return dateMatches && statusMatches
                    
            })
          
            if(filterData?.length>0){
                setAbandonData(filterData)
            }
            else{
                setAbandonData([])
            }
        }
    },[dateInput,selectUserType])

    const clearForm = ()=>{
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setAbandonData(filterAbandonData);
        setSelectUserType(null)
        

    }
    const downloadExcelBtn = ()=>{
        const filterData = abandonedData?.map((ele)=>{
           const FullName =  `${ele?.user_ip || (ele?.u_user_first_name ? `${ele.u_user_first_name} ${ele.u_user_last_name || ''}` : '')}`
            return{
                CartID:ele?.u_cart_id,
                FullName :FullName,
                Email:ele?.user_ip?"":ele?.u_user_email,
                SKU:ele?.p_product_sku,
                ProductPrice:ele?.p_product_price,
                //Status:ele?.m_designer_active,
                CreatedAt : moment(ele?.first_sent_at).format("YYYY-MM-DD")
            }
        })

       downloadSheet(filterData,"abandoned")

       //console.log("filterData",filterData)
    }

    const sendEmailHandler = async(user_id)=>{
        //console.log("sendEmailHandler")
        try {
            const payload = {
                user_id:user_id
            }
            const response = await MasterServices.sendEmailAbondantEmail(payload);
            console.log("sendEmailHandler", response)
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                
            } else {
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            toast.error(error?.data?.message)
        }
        
    }
    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Abandoned List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the abandoned.</p>
                </div>

            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            User Type
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectUserType}
                                // // onBlur={handleBlur}
                                onChange={(selectedOption)=>{
                                    setSelectUserType(selectedOption)
                                }}
                                name="userStatus"
                                options={userType}
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>

                    </div>
                    <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        Start Date
                    </label>
                    <div className="flex">
                        <input
                            type="date"
                            name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    startDate:e.target.value
                                   }))
                                }}

                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        End Date
                    </label>
                    <div className="flex">
                        <input
                            type="date"
                            name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                   setDateInput((prev)=>({
                                    ...prev,
                                    endDate:e.target.value
                                   }))
                                }}

                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                        />
                    </div>
                </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
                
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>

            {
            loading?
            <Spinner/>
            :
            <Table
                columns={abandoned_columns({sendEmailHandler})}
                data={abandonedData}
            />
        }
        </>
    )
}


export default Abandoned